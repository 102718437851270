import {
  CloseIcon,
  ErrorIcon,
  InfoIcon,
  SuccessIcon
} from '@components/icons/icons'
import { ReactElement, useState } from 'react'

type AlertProps = {
  type: 'success' | 'error' | 'info' | 'warning'
  title: string
  description?: string
  closable?: boolean
  icon: ReactElement
}

export const Alert: React.FC<AlertProps> = ({
  type,
  title,
  icon,
  description,
  closable = false
}) => {
  const [isShown, setIsShown] = useState(true)

  const handleClose = () => {
    setIsShown(false)
  }

  let internalIcon: ReactElement
  let color = ''

  if (type === 'success') {
    internalIcon = <SuccessIcon />
    color = 'green'
  } else if (type === 'error') {
    internalIcon = <ErrorIcon className="text-red-500" />
    color = 'red'
  } else if (type === 'warning') {
    internalIcon = icon && <ErrorIcon className="text-yellow-500" />
    color = 'yellow'
  } else {
    internalIcon = <InfoIcon className="text-lg text-blue-800" />
    color = 'blue'
  }

  if (!isShown) {
    return null
  }

  return (
    <div className={`px-3 py-3 rounded-md bg-${color}-50`}>
      <div className="flex">
        <div className="mt-1">{internalIcon}</div>
        <div className={`${type !== 'warning' && 'ml-3'}`}>
          <h3 className={`text-sm font-medium text-${color}-800 text-left`}>
            {title}
          </h3>
          {description && (
            <div className={`mt-2 text-sm text-${color}-700 text-left`}>
              <p>{description}</p>
            </div>
          )}
        </div>
        <div className="pl-3 ml-auto">
          {closable && (
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={handleClose}
                className={`inline-flex bg-${color}-50 rounded-md p-1.5 text-${color}-500 hover:bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${color}-50 focus:ring-${color}-600`}>
                <span className="sr-only">Dismiss</span>
                <CloseIcon />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
