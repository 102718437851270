import { useEffect, useState } from 'react'
import { Modal } from '@components/modal'
import { Button } from '@components/forms'
import * as Sentry from "@sentry/browser";
import { Alert } from '@components/alert';
import { useRouter } from 'next/router'

export function RetryModal({ quoteLocator, policyLocator, retryFunction, closeRetryModal, loading = false }) {

  const [shouldStartOver, setShouldStartOver] = useState(false);
  const router = useRouter()

  useEffect(() => {
    Sentry.captureMessage(`Request failed. Retry Modal Opened.`, { level: "error" })
  }, [])
  return (
    <>
      <Modal
        showsModal={true}
        description={
          <div className="flex flex-column justify-content-center text-center mb-3">
            <div>
              <p className="text-2xl headline-blue font-Oswald mb-2 font-bold">
                Your Request cannot be serviced due to some Technical reasons.
                Please {shouldStartOver ? 'start over' : 'retry'}
              </p>
            </div>
            <div>
              <p className="text-2xl headline-blue font-Oswald mb-2 font-bold">
                Or
              </p>
            </div>
            <div>
              <p className="text-2xl headline-blue font-Oswald mb-2 font-bold">
                Call (800)-814-7231
              </p>
            </div>

            {quoteLocator ? (
              <div>
                <p className="font-color-asphalt-gray-80 font-bold text-base">
                  Quote Number : {quoteLocator}
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        }
        body={
          <>
            <div className="flex flex-row column-gap-3 justify-content-center">
              <Button
                type="button"
                onClick={() => router.push("/")}
                className="my-2 h-10 w-auto font-bold py-3 sm:py-2 px-4 text-black border-2 border-orange-600 bg-white">
                <span className="font-color-asphalt-gray">CANCEL</span>
              </Button>
              {shouldStartOver && (
                <Button
                type="button"
                onClick={async () => {
                  localStorage.clear();
                  sessionStorage.clear();
                  window.location = "/";
                }}
                className="my-2 h-10 w-auto font-bold py-2 px-3 text-white  button-orange-bg"
              >
                Start Over
              </Button>
              )}
              {!shouldStartOver && (
                <Button
                type="button"
                disabled={loading}
                onClick={async () => {
                  if (typeof retryFunction === "function") {
                    const response = await retryFunction()
                    if (response === false) {
                      setShouldStartOver(true);
                    }
                  }
                }}
                
                className="my-2 h-10 w-auto font-bold py-2 px-3 text-white  button-orange-bg">
                <div className="font-semibold text-sm text-white ">RETRY</div>
              </Button>
              )}
            </div>
          </>
        }
      />
    </>
  )
}
