import { useState, useEffect, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import moment from 'moment'
import _ from 'lodash'
import { ThreeDots } from 'react-loader-spinner'
import { NextSeo } from 'next-seo'
import { InputMask } from 'primereact/inputmask'
import { InputText } from 'primereact/inputtext'
import { Tooltip } from 'primereact/tooltip'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Button } from '../../components/forms'
import { Modal } from '../../components/modal'
import { IMAGE_PATHS, ROUTE_PATHS, anonymous_user } from '../../constants'
import { RetryModal } from '@components/retrymodal'
import { revertAll } from '@pages/feature/common/commonAction'
import { omit } from 'lodash'
import { getAgentFieldValues, getAgencyFieldValues } from '@pages/helpers'
import {
  getQuote,
  setMakeData,
  setModelData,
  setQuickQuoteVehicleInfo,
  setQuoteData,
  setYearData,
  setBasicLimitData,
  setAdvLimitData,
  setPreLimitData,
  setPolicyCoveragesData,
  setVehicleCoveragesData,
  getInsuranceplan
} from '../feature/common/home/homeSlice'
import { setPolicyHolder } from '../feature/zipvalidation/zipvalidationSlice'
import {
  setPolicyLocator,
  setQuoteLocator,
  setZipCode,
  setStateValue
} from '../feature/zipvalidation/zipvalidationSlice'
import * as Sentry from '@sentry/browser'

const url = `${process.env.FRONTEND_BASE_URL}${ROUTE_PATHS.HOME}`
const title = 'Boundless Rider | Insurance'
const description = 'Home Page'

const parseQueryString = (queryString) => {
  const obj = {}
  const searchParams = new URLSearchParams(queryString)
  searchParams?.forEach((value, key) => {
    obj[key] = value
  })
  return obj
}

const Home = ({ props }) => {
  const dispatch = useDispatch()
  const zipData = useSelector((state) => state.zipValidation)
  const [click, setClick] = useState(false)
  const [zip, setZip] = useState('')
  const [loader, setLoader] = useState(false)
  const router = useRouter()
  const [vehicle, setVehicle] = useState('')
  const [vehicleerror, setVehicleError] = useState(false)
  const [open, setOpen] = useState(false)
  const [year, setYear] = useState('')
  const [yearFlag, setYearFlag] = useState(true)
  const [selyear, setSelyear] = useState('')
  const [make, setMake] = useState('')
  const [makeFlag, setMakeFlag] = useState(true)
  const [selmake, setSelmake] = useState('')
  const [model, setModel] = useState('')
  const [modelFlag, setModelFlag] = useState(true)
  const [retryPopUp, setRetryPopUp] = useState(false)
  const [selmodel, setSelmodel] = useState('')
  const [vehicleDetails, setVehicleDetails] = useState({})
  const [vehicleDataIsValid, setVehicleDataIsValid] = useState(false)
  const [age, setAge] = useState('')
  const [ageerror, setAgeError] = useState(false)
  const [ageerror1, setAgeError1] = useState(false)
  const [ageerror2, setAgeError2] = useState(false)
  const [policyAgeError, setpolicyAgeError] = useState(false)
  const [history, setHistory] = useState(false)
  const [disabelzip, setDisabelZip] = useState(false)
  const [policyHolder, setPolicyHolder] = useState('')
  const [state, setState] = useState('')

  const [perPersonLimitBI, setPerPersonLimitBI] = useState('')
  const [perAccidentLimitBI, setPerAccidentLimitBI] = useState('')
  const [propertyDamage, setPropertyDamage] = useState('')
  const [perPersonLimitGP, setPerPersonLimitGP] = useState('')
  const [perAccidentLimitGP, setPerAccidentLimitGP] = useState('')
  const inputMarkRef = useRef(null)

  const parsedQueryString = parseQueryString(window.location.search)
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      !!Object.keys(parsedQueryString).length
    ) {
      localStorage.setItem(
        'utm_tracking_string',
        JSON.stringify(parsedQueryString)
      )
    }
  }, [parsedQueryString])

  useEffect(() => {
    if (inputMarkRef.current) {
      inputMarkRef.current.focus()
    }
  }, [inputMarkRef])

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.pathname == '/') {
      clearStorageData()
    }
  }, [window.location.pathname])

  const agentData = JSON.parse(localStorage.getItem('agent'))

  const clearStorageData = () => {
    localStorage.clear()
    sessionStorage.clear()
    dispatch(revertAll())
    localStorage.setItem('appInitializationFlag', 'true')
    agentData && localStorage.setItem('agent', JSON.stringify(agentData))
  }
  const zipHandler = (e) => {
    setZip(e.target.value)
  }

  const closeModalError = () => {
    setOpen(false)
  }

  const backToHome = () => {
    setOpen(false)
    resetFields()
    setVehicle('')
    router.push(ROUTE_PATHS.HOME)
  }

  const resetFields = () => {
    setAge('')
    setYear('')
    setMake('')
    setModel('')
    setSelyear('')
    setSelmake('')
    setSelmodel('')
    setHistory('')
    setVehicleDataIsValid(false)
  }

  const getYearData = async (vehicleType) => {
    setYearFlag(false)
    setMakeFlag(true)
    setModelFlag(true)
    setSelmake('')
    setSelmodel('')
    let body = {
      vehicle: vehicleType
    }
    await fetch('/api/vehicle/getinfo', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setYear(data?.years)
        setYearFlag(false)
      })
  }
  const getVehicleYear = async (year) => {
    setMakeFlag(true)
    setModelFlag(true)
    setSelmake('')
    setSelmodel('')
    setSelyear(year)
    let body = {
      vehicle: `${vehicle === 'ATV/UTV' ? 'ATV,UTV' : vehicle}&year=${year}`
    }
    await fetch('/api/vehicle/getinfo', {
      method: 'POST',
      body: JSON.stringify(body),

      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setSelmake('')
        setSelmodel('')
        setMake(data?.makes)
        setMakeFlag(false)
      })
  }

  const getVehicleMake = async (make) => {
    setModelFlag(true)
    setSelmake(make)
    setSelmodel('')
    let body = {
      vehicle: `${
        vehicle === 'ATV/UTV' ? 'ATV,UTV' : vehicle
      }&year=${selyear}&make=${make}`
    }
    await fetch('/api/vehicle/getinfo', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',

        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setModel(data?.models)
        setModelFlag(false)
      })
  }
  const getVehicleModel = async (model) => {
    setSelmodel(model)
    let body = {
      vehicle: `${
        vehicle === 'ATV/UTV' ? 'ATV,UTV' : vehicle
      }&year=${selyear}&make=${selmake}&model=${model}`
    }
    await fetch('/api/vehicle/getinfo', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',

        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data?.body_type &&
          data?.displacement &&
          data?.msrp &&
          data?.model
        ) {
          setOpen(false)
          setVehicleDetails(data)
          dispatch(setQuickQuoteVehicleInfo(data))
          setVehicleDataIsValid(true)
        } else {
          Sentry.captureMessage(
            `Vehicle lookup data missing: ${JSON.stringify(data)}`,
            { level: 'error' }
          )
          setOpen(true)
          setVehicleDataIsValid(false)
        }
      })
  }

  function formatDate(date) {
    const d = new Date(date)
    let month = (d.getMonth() + 1).toString()
    let day = d.getDate().toString()
    const year = d.getFullYear()

    if (month?.length < 2) month = '0' + month
    if (day?.length < 2) day = '0' + day
    return [year, month, day].join('-')
  }

  const limitFilter = (data, code) => {
    const filterData = data.limits.filter((d) => d.code === code)
    return filterData != '' ? filterData[0].limits : ''
  }

  function limitOrder(data) {
    if (data) {
      const sortData = data.sort(function (a, b) {
        let c = a != '' ? a.split('/') : ''
        c = c != '' ? c[0].replace(/[^a-zA-Z0-9 ]/g, '') : ''
        let d = b != '' ? b.split('/') : ''
        d = d != '' ? d[0].replace(/[^a-zA-Z0-9 ]/g, '') : ''
        return c - d
      })
      return sortData
    } else {
      return ''
    }
  }

  const handleClick = async () => {
    const payload = { zipcode: zip }

    await fetch('/api/usercontroller/zipcode', {
      method: 'POST',
      body: JSON.stringify(payload),

      headers: {
        // auth: auth.authorizationToken,

        'Content-Type': 'application/json',

        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        // Send zip code to zipError
        dispatch(setZipCode(zip))
        if (data.values?.length == 0) {
          router.push(ROUTE_PATHS.ZIP_ERROR)
        } else {
          setClick(true)
          setDisabelZip(true)
          setState(data.values[0])
          dispatch(setStateValue(data.values[0]))
        }

        const stateName = data.values[0]

        dispatch(
          getInsuranceplan({
            state: data.values[0],
            package: 'basic'
          })
        )
          .unwrap()
          .then((data) => {
            const limitBI = limitFilter(data, 'bi')
            const splitBI = limitBI.split('/')
            setPerPersonLimitBI(splitBI[0].replace(/[^a-zA-Z0-9 ]/g, ''))
            setPerAccidentLimitBI(splitBI[1].replace(/[^a-zA-Z0-9 ]/g, ''))

            const limitPD = limitFilter(data, 'pd')
            const property_damage = limitPD.trim()
            setPropertyDamage(property_damage.replace(/[^a-zA-Z0-9 ]/g, ''))

            const limitGP = limitFilter(data, 'gp')
            const splitGP = limitGP.split('/')
            setPerPersonLimitGP(splitGP[0].replace(/[^a-zA-Z0-9 ]/g, ''))
            setPerAccidentLimitGP(splitGP[1].replace(/[^a-zA-Z0-9 ]/g, ''))

            const basicPerilValues = {
              perPersonLimitBI: splitBI[0].replace(/[^a-zA-Z0-9 ]/g, ''),
              perAccidentLimitBI: splitBI[1].replace(/[^a-zA-Z0-9 ]/g, ''),
              propertyDamage: property_damage.replace(/[^a-zA-Z0-9 ]/g, ''),
              perPersonLimitGP: splitGP[0].replace(/[^a-zA-Z0-9 ]/g, ''),
              perAccidentLimitGP: splitGP[1].replace(/[^a-zA-Z0-9 ]/g, '')
            }

            if (stateName === 'Illinois') {
              const limitUMBI = limitFilter(data, 'umbi')
              const splitUMBI = limitUMBI.split('/')
              basicPerilValues.perPersonLimitUMBI = splitUMBI[0].replace(
                /[^a-zA-Z0-9 ]/g,
                ''
              )
              basicPerilValues.perAccidentLimitUMBI = splitUMBI[1].replace(
                /[^a-zA-Z0-9 ]/g,
                ''
              )
            }
            dispatch(setBasicLimitData(basicPerilValues))
          })

        dispatch(
          getInsuranceplan({
            state: data.values[0],
            package: 'advance'
          })
        )
          .unwrap()
          .then((data) => {
            const limitBI = limitFilter(data, 'bi')
            const splitBI = limitBI.split('/')
            const limitPD = limitFilter(data, 'pd')
            const property_damage = limitPD.trim()
            const limitGP = limitFilter(data, 'gp')
            const splitGP = limitGP.split('/')
            const limitUMBI = limitFilter(data, 'umbi')
            const splitUMBI = limitUMBI.split('/')
            const limitUIMBI = limitFilter(data, 'uimbi')
            const splitUIMBI = limitUIMBI ? limitUIMBI.split('/') : ''

            dispatch(
              setAdvLimitData({
                perPersonLimitBI: splitBI[0].replace(/[^a-zA-Z0-9 ]/g, ''),
                perAccidentLimitBI: splitBI[1].replace(/[^a-zA-Z0-9 ]/g, ''),
                propertyDamage: property_damage.replace(/[^a-zA-Z0-9 ]/g, ''),
                perPersonLimitGP: splitGP[0].replace(/[^a-zA-Z0-9 ]/g, ''),
                perAccidentLimitGP: splitGP[1].replace(/[^a-zA-Z0-9 ]/g, ''),
                perPersonLimitUMBI: splitUMBI[0].replace(/[^a-zA-Z0-9 ]/g, ''),
                perAccidentLimitUMBI: splitUMBI[1].replace(
                  /[^a-zA-Z0-9 ]/g,
                  ''
                ),
                perPersonLimitUIMBI: splitUIMBI[0]
                  ? splitUIMBI[0].replace(/[^a-zA-Z0-9 ]/g, '')
                  : '',
                perAccidentLimitUIMBI: splitUIMBI[1]
                  ? splitUIMBI[1].replace(/[^a-zA-Z0-9 ]/g, '')
                  : '',
                limitUMPD: limitFilter(data, 'umpd').replace(
                  /[^a-zA-Z0-9 ]/g,
                  ''
                )
              })
            )
          })

        dispatch(
          getInsuranceplan({
            state: data.values[0],
            package: 'premium'
          })
        )
          .unwrap()
          .then((data) => {
            const limitBI = limitFilter(data, 'bi')
            const splitBI = limitBI.split('/')
            const limitPD = limitFilter(data, 'pd')
            const property_damage = limitPD.trim()
            const limitGP = limitFilter(data, 'gp')
            const splitGP = limitGP.split('/')
            const limitUMBI = limitFilter(data, 'umbi')
            const splitUMBI = limitUMBI.split('/')
            const limitUIMBI = limitFilter(data, 'uimbi')
            const splitUIMBI = limitUIMBI ? limitUIMBI.split('/') : ''

            dispatch(
              setPreLimitData({
                perPersonLimitBI: splitBI[0].replace(/[^a-zA-Z0-9 ]/g, ''),
                perAccidentLimitBI: splitBI[1].replace(/[^a-zA-Z0-9 ]/g, ''),
                propertyDamage: property_damage.replace(/[^a-zA-Z0-9 ]/g, ''),
                perPersonLimitGP: splitGP[0].replace(/[^a-zA-Z0-9 ]/g, ''),
                perAccidentLimitGP: splitGP[1].replace(/[^a-zA-Z0-9 ]/g, ''),
                perPersonLimitUMBI: splitUMBI[0].replace(/[^a-zA-Z0-9 ]/g, ''),
                perAccidentLimitUMBI: splitUMBI[1].replace(
                  /[^a-zA-Z0-9 ]/g,
                  ''
                ),
                perPersonLimitUIMBI: splitUIMBI[0]
                  ? splitUIMBI[0].replace(/[^a-zA-Z0-9 ]/g, '')
                  : '',
                perAccidentLimitUIMBI: splitUIMBI[1]
                  ? splitUIMBI[1].replace(/[^a-zA-Z0-9 ]/g, '')
                  : '',
                limitUMPD: limitFilter(data, 'umpd').replace(
                  /[^a-zA-Z0-9 ]/g,
                  ''
                )
              })
            )
          })

        dispatch(
          getInsuranceplan({
            state: data.values[0],
            package: 'custom'
          })
        )
          .unwrap()
          .then((data) => {
            const limitBIGP = data.limits.filter((d) => d.code === 'bi')
            const bigpPolicyCoverage = limitBIGP.map((d) => d.limits)
            const limitPD = data.limits.filter((d) => d.code === 'pd')
            const pdPolicyCoverage = limitPD.map((d) => d.limits)

            const limitUMBI = data.limits.filter((d) => d.code === 'umbi')
            const umbiPolicyCoverage = limitUMBI.map((d) => d.limits)

            const limitUIMBI = data.limits.filter((d) => d.code === 'uimbi')
            const uimbiPolicyCoverage = limitUIMBI.map((d) => d.limits)

            const limitUMPD = data.limits.filter((d) => d.code === 'umpd')
            const umpdPolicyCoverage = limitUMPD.map((d) => d.limits)

            const limitPIP = data.limits.filter((d) => d.code === 'pip')
            const pipPolicyCoverage = limitPIP.map((d) => d.limits)

            const limitMP = data.limits.filter((d) => d.code === 'mp')
            const mpPolicyCoverage = limitMP.map((d) => d.limits)

            const limitTTPD = data.limits.filter((d) => d.code === 'ttpd')
            const ttpdPolicyCoverage = limitTTPD.map((d) => d.limits)

            dispatch(
              setPolicyCoveragesData({
                bigp: limitOrder(bigpPolicyCoverage),
                pd: limitOrder(pdPolicyCoverage),
                umbi: limitOrder(umbiPolicyCoverage),
                uimbi: limitOrder(uimbiPolicyCoverage),
                umpd: limitOrder(umpdPolicyCoverage),
                pip: limitOrder(pipPolicyCoverage),
                mp: limitOrder(mpPolicyCoverage),
                ttpd: limitOrder(ttpdPolicyCoverage)
              })
            )

            const limitcomp = data.limits.filter((d) => d.code === 'comp')
            const compVehicleCoverages = limitcomp.map((d) => d.limits)

            const limitcoll = data.limits.filter((d) => d.code === 'coll')
            const collVehicleCoverages = limitcoll.map((d) => d.limits)

            const limitopt_me = data.limits.filter((d) => d.code === 'opt_me')
            const opt_meVehicleCoverages = limitopt_me.map((d) => d.limits)

            const limitopt_sd = data.limits.filter((d) => d.code === 'opt_sd')
            const opt_sdVehicleCoverages = limitopt_sd.map((d) => d.limits)

            const limitd_ded = data.limits.filter((d) => d.code === 'd_ded')
            const d_dedVehicleCoverages = limitd_ded.map((d) => d.limits)

            const limitpdp = data.limits.filter((d) => d.code === 'pdp')
            const pdpVehicleCoverages = limitpdp.map((d) => d.limits)

            const limittripInt = data.limits.filter((d) => d.code === 'tripInt')
            const tripIntVehicleCoverages = limittripInt.map((d) => d.limits)

            const limitsubm = data.limits.filter((d) => d.code === 'subm')
            const submVehicleCoverages = limitsubm.map((d) => d.limits)

            const limitepu = data.limits.filter((d) => d.code === 'epu')
            const epuVehicleCoverages = limitepu.map((d) => d.limits)

            const limitrc = data.limits.filter((d) => d.code === 'rc')
            const rcVehicleCoverages = limitrc.map((d) => d.limits)

            const limitra = data.limits.filter((d) => d.code === 'ra')
            const raVehicleCoverages = limitra.map((d) => d.limits)

            const limitrr = data.limits.filter((d) => d.code === 'rr')
            const rrVehicleCoverages = limitrr.map((d) => d.limits)

            dispatch(
              setVehicleCoveragesData({
                comp: limitOrder(compVehicleCoverages),
                coll: limitOrder(collVehicleCoverages),
                opt_me: limitOrder(opt_meVehicleCoverages),
                opt_sd: limitOrder(opt_sdVehicleCoverages),
                d_ded: d_dedVehicleCoverages,
                pdp: pdpVehicleCoverages,
                tripInt: limitOrder(tripIntVehicleCoverages),
                subm: limitOrder(submVehicleCoverages),
                epu: epuVehicleCoverages,
                rc: rcVehicleCoverages,
                ra: raVehicleCoverages,
                rr: limitOrder(rrVehicleCoverages)
              })
            )
          })
      })
      .catch((error) => {})
  }

  const agevalidation = (e) => {
    if (e.target.value < 18) {
      setpolicyAgeError(true)
    } else {
      setpolicyAgeError(false)
      setAgeError(false)
      setAgeError1(false)
      setAgeError2(false)
      setAge(e.target.value)
    }
  }
  const agent = getAgentFieldValues(agentData?.agent)
  const agency = getAgencyFieldValues(agentData?.agency)
  const utm_tracking_string = JSON.stringify(agentData?.utm_tracking_string)

  function payload(policyholderlocator = {}) {
    if (vehicle === 'Motorcycle') {
      return {
        finalize: 'false',
        policyholderLocator: policyholderlocator,
        productName: 'Boundless Rider Motorcycle and Motorsports Program',
        policyStartTimestamp: new Date().getTime(),
        policyEndTimestamp: new Date(
          new Date().getFullYear() + 1,
          new Date().getMonth(),
          new Date().getDate()
        ).getTime(),
        fieldValues: {
          driving_history: history,
          driver_Age: age,
          gender: 'Male',
          marital_status: 'Married',
          'operator’s_date_of_birth': '1957-09-08',
          any_prior_insurance_with_any_carrier: 'No',
          insurance_score: 'No hit',
          sales_channel: 'Direct online',
          age_credit_home_owner: 'Own',
          house_hold_composition: '2',
          drug_alcohol_program: 'No',
          safety_course: 'Yes',
          safety_course_date: formatDate(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 3,
              new Date().getDate()
            )
          ), // "2021-10-09",
          license_state: state,
          mvr_surcharge: 'No foreign or international license',
          driving_experience: '5',
          motorcycle_endorsement: 'Yes',
          safe_rider_program: 'Yes',
          safety_rider_program_date: '2021-09-08',
          motor_group: 'No',
          addl_operators: 'No',
          multiline_discount: '0',
          felony: 'No',
          insurance_fraud_conviction: 'No',
          insurance_fraud_conviction_insured: 'No',
          atfault_claims_past_3_years: 'No',
          quote_type: 'Quick Quote',
          payment_plan: 'Automatic',
          user_id: anonymous_user,
          ...agent,
          ...agency,
          utm_tracking_string
        },
        exposures: [
          {
            exposureName: 'Vehicle',
            fieldValues: {
              year: selyear,
              vehicle_type: vehicle,
              postal_code: zip,
              make: selmake,
              model: selmodel,
              state: state,
              series_name: vehicleDetails?.model ? vehicleDetails?.model : '',
              vehicle_class: vehicleDetails?.body_type,
              body_type: vehicleDetails?.body_type,
              msrp: vehicleDetails?.msrp,
              symbol_misc_code: '0',
              engine_size_cc_kw: vehicleDetails?.displacement,
              anti_theft: 'No',
              vehicle_modification: 'No',
              multiple_vehicle_discount: '1',
              road_use: 'Yes',
              garaging: 'Yes',
              territory_city: 'Addison',
              territory_state: state,
              territory_postalcode: zip,
              low_mileage: '0',
              primary_vehicle_use_hire: 'No',
              primary_vehicle_use_nonhire: 'No',
              ownership_status: 'Owned'
            },
            perils: [
              {
                name: 'Guest Passenger',
                fieldValues: {
                  per_person_limit: [perPersonLimitGP],
                  per_accident_limit: [perAccidentLimitGP]
                }
              },
              {
                name: 'Property Damage',
                fieldValues: {
                  limit: [propertyDamage]
                }
              },
              {
                name: 'Bodily Injury (BI)',
                fieldValues: {
                  per_person_limit: [perPersonLimitBI],
                  per_accident_limit: [perAccidentLimitBI]
                }
              },
              {
                name: 'Comprehensive',
                fieldValues: {
                  actual_cash_value_of_the_vehicle_and_optional_equipment: [
                    '60000'
                  ],
                  helmet_and_safety_apparel: ['2000'],
                  deductibles: ['1000']
                }
              },
              {
                name: 'Collision',
                fieldValues: {
                  actual_cash_value_of_the_vehicle_and_optional_equipment: [
                    '60000'
                  ],
                  helmet_and_safety_apparel: ['2000'],
                  deductibles: ['1000']
                }
              }
            ]
          }
        ]
      }
    } else if (vehicle === 'ATV,UTV') {
      return {
        finalize: 'false',
        policyholderLocator: policyholderlocator,
        productName: 'Boundless Rider Motorcycle and Motorsports Program',
        policyStartTimestamp: new Date().getTime(),
        policyEndTimestamp: new Date(
          new Date().getFullYear() + 1,
          new Date().getMonth(),
          new Date().getDate()
        ).getTime(),
        fieldValues: {
          driving_history: history,
          driver_Age: age,
          gender: 'Male',
          marital_status: 'Married',
          'operator’s_date_of_birth': '1989-09-08',
          any_prior_insurance_with_any_carrier: 'No',
          insurance_score: 'No hit',
          sales_channel: 'Direct online',
          age_credit_home_owner: 'Own',
          house_hold_composition: '2',
          drug_alcohol_program: 'No',
          safety_course: 'Yes',
          safety_course_date: formatDate(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 3,
              new Date().getDate()
            )
          ),
          mvr_surcharge: 'No foreign or international license',
          license_state: state,
          driving_experience: '5',
          motorcycle_endorsement: 'Yes',
          safe_rider_program: 'No',
          motor_group: 'No',
          addl_operators: 'No',
          felony: 'No',
          insurance_fraud_conviction: 'No',
          insurance_fraud_conviction_insured: 'No',
          multiline_discount: '0',
          atfault_claims_past_3_years: 'No',
          quote_type: 'Quick Quote',
          payment_plan: 'Automatic',
          user_id: anonymous_user,
          ...agent,
          ...agency,
          utm_tracking_string
        },
        exposures: [
          {
            exposureName: 'Vehicle',
            fieldValues: {
              year: selyear,
              vehicle_type: vehicle === 'ATV,UTV' ? 'ATV/UTV' : 'ATV',
              state: state,
              model: selmodel,
              postal_code: zip,
              make: selmake,
              wheels: '4',
              vehicle_capacity: '2',
              symbol_misc_code: '0',
              series_name: vehicleDetails?.model ? vehicleDetails?.model : '',
              vehicle_class: vehicleDetails?.vehicle_type,
              body_type: vehicleDetails?.body_type,
              msrp: vehicleDetails?.msrp,
              engine_size_cc_kw: vehicleDetails?.displacement,
              specific_vehicle_type: vehicleDetails?.vehicle_type,
              anti_theft: 'No',
              vehicle_modification: 'No',
              multiple_vehicle_discount: '1',
              territory_city: 'Pearland',
              territory_state: state,
              territory_postalcode: zip,
              primary_vehicle_use_hire: 'No',
              primary_vehicle_use_nonhire: 'No',
              road_use: 'No',
              garaging: 'Yes',
              ownership_status: 'Owned'
            },
            perils: [
              {
                name: 'Guest Passenger',
                fieldValues: {
                  per_person_limit: [perPersonLimitGP],
                  per_accident_limit: [perAccidentLimitGP]
                }
              },
              {
                name: 'Property Damage',
                fieldValues: {
                  limit: [propertyDamage]
                }
              },
              {
                name: 'Bodily Injury (BI)',
                fieldValues: {
                  per_person_limit: [perPersonLimitBI],
                  per_accident_limit: [perAccidentLimitBI]
                }
              },
              {
                name: 'Comprehensive',
                fieldValues: {
                  actual_cash_value_of_the_vehicle_and_optional_equipment: [
                    '60000'
                  ],
                  helmet_and_safety_apparel: ['2000'],
                  deductibles: ['1000']
                }
              },
              {
                name: 'Collision',
                fieldValues: {
                  actual_cash_value_of_the_vehicle_and_optional_equipment: [
                    '60000'
                  ],
                  helmet_and_safety_apparel: ['2000'],
                  deductibles: ['1000']
                }
              }
            ]
          }
        ]
      }
    } else if (vehicle === 'Dirtbike') {
      return {
        finalize: 'false',
        policyholderLocator: policyholderlocator,
        productName: 'Boundless Rider Motorcycle and Motorsports Program',
        policyStartTimestamp: new Date().getTime(),
        policyEndTimestamp: new Date(
          new Date().getFullYear() + 1,
          new Date().getMonth(),
          new Date().getDate()
        ).getTime(),
        fieldValues: {
          driving_history: history,
          driver_Age: age,
          quote_type: 'Quick Quote',
          gender: 'Male',
          marital_status: 'Married',
          'operator’s_date_of_birth': '1957-09-08',
          any_prior_insurance_with_any_carrier: 'No',
          insurance_score: 'No hit',
          safety_course: 'Yes',
          safety_course_date: formatDate(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 3,
              new Date().getDate()
            )
          ),
          sales_channel: 'Direct online',
          age_credit_home_owner: 'Own',
          house_hold_composition: '2',
          drug_alcohol_program: 'No',
          mvr_surcharge: 'No foreign or international license',
          license_state: state,
          driving_experience: '5',
          motorcycle_endorsement: 'Yes',
          safe_rider_program: 'No',
          motor_group: 'No',
          addl_operators: 'No',
          felony: 'No',
          insurance_fraud_conviction: 'No',
          insurance_fraud_conviction_insured: 'No',
          atfault_claims_past_3_years: 'No',
          multiline_discount: '0',
          quote_type: 'Quick Quote',
          payment_plan: 'Automatic',
          user_id: anonymous_user,
          ...agent,
          ...agency,
          utm_tracking_string
        },
        exposures: [
          {
            exposureName: 'Vehicle',
            fieldValues: {
              year: selyear,
              vehicle_type: vehicle,
              state: state,
              model: selmodel,
              postal_code: zip,
              make: selmake,
              symbol_misc_code: '0',
              vehicle_class: 'DirtBike',
              series_name: vehicleDetails?.model ? vehicleDetails?.model : '',
              body_type: vehicleDetails?.body_type,
              msrp: vehicleDetails?.msrp,
              engine_size_cc_kw: vehicleDetails?.displacement,
              anti_theft: 'No',
              vehicle_modification: 'No',
              multiple_vehicle_discount: '1',
              territory_city: 'Pearland',
              territory_state: state,
              territory_postalcode: zip,
              primary_vehicle_use_hire: 'No',
              primary_vehicle_use_nonhire: 'No',
              road_use: 'No',
              garaging: 'Yes',
              ownership_status: 'Owned'
            },
            perils: [
              {
                name: 'Guest Passenger',
                fieldValues: {
                  per_person_limit: [perPersonLimitGP],
                  per_accident_limit: [perAccidentLimitGP]
                }
              },
              {
                name: 'Property Damage',
                fieldValues: {
                  limit: [propertyDamage]
                }
              },
              {
                name: 'Bodily Injury (BI)',
                fieldValues: {
                  per_person_limit: [perPersonLimitBI],
                  per_accident_limit: [perAccidentLimitBI]
                }
              },
              {
                name: 'Comprehensive',
                fieldValues: {
                  actual_cash_value_of_the_vehicle_and_optional_equipment: [
                    '60000'
                  ],
                  helmet_and_safety_apparel: ['2000'],
                  deductibles: ['1000']
                }
              },
              {
                name: 'Collision',
                fieldValues: {
                  actual_cash_value_of_the_vehicle_and_optional_equipment: [
                    '60000'
                  ],
                  helmet_and_safety_apparel: ['2000'],
                  deductibles: ['1000']
                }
              }
            ]
          }
        ]
      }
    } else if (vehicle === 'E-BikeAssist') {
      return {
        finalize: 'false',
        policyholderLocator: policyholderlocator,
        productName: 'Boundless Rider Motorcycle and Motorsports Program',
        policyStartTimestamp: new Date().getTime(),
        policyEndTimestamp: new Date(
          new Date().getFullYear() + 1,
          new Date().getMonth(),
          new Date().getDate()
        ).getTime(),
        fieldValues: {
          driving_history: history,
          driver_Age: age,
          gender: 'Male',
          marital_status: 'Married',
          'operator’s_date_of_birth': '1957-09-08',
          any_prior_insurance_with_any_carrier: 'No',
          insurance_score: 'No hit',
          sales_channel: 'Direct online',
          age_credit_home_owner: 'Own',
          house_hold_composition: '2',
          drug_alcohol_program: 'No',
          safety_course: 'Yes',
          safety_course_date: formatDate(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 3,
              new Date().getDate()
            )
          ),
          license_state: state,
          mvr_surcharge: 'No foreign or international license',
          driving_experience: '5',
          motorcycle_endorsement: 'Yes',
          safe_rider_program: 'Yes',
          safety_rider_program_date: '2021-09-08',
          motor_group: 'No',
          addl_operators: 'No',
          felony: 'No',
          insurance_fraud_conviction: 'No',
          insurance_fraud_conviction_insured: 'No',
          atfault_claims_past_3_years: 'No',
          multiline_discount: '0',
          quote_type: 'Quick Quote',
          payment_plan: 'Automatic',
          user_id: anonymous_user,
          ...agent,
          ...agency,
          utm_tracking_string
        },
        exposures: [
          {
            exposureName: 'Vehicle',
            fieldValues: {
              year: year,
              vehicle_type: vehicle,
              state: state,
              model: model,
              postal_code: zip,
              make: make,
              vehicle_class: 'E-BikeAssist',
              series_name: vehicleDetails?.model ? vehicleDetails?.model : '',
              fuel_type: 'electric',
              msrp: '1000',
              symbol_misc_code: 'A',
              engine_size_cc_kw: '750',
              anti_theft: 'No',
              vehicle_modification: 'No',
              multiple_vehicle_discount: '1',
              territory_city: 'Pearland',
              territory_state: state,
              territory_postalcode: zip,
              primary_vehicle_use_hire: 'No',
              primary_vehicle_use_nonhire: 'No',
              road_use: 'Yes',
              garaging: 'Yes',
              ownership_status: 'Owned',
              serial_number: '1234'
            },
            perils: [
              {
                name: 'Guest Passenger',
                fieldValues: {
                  per_person_limit: [perPersonLimitGP],
                  per_accident_limit: [perAccidentLimitGP]
                }
              },
              {
                name: 'Property Damage',
                fieldValues: {
                  limit: [propertyDamage]
                }
              },
              {
                name: 'Bodily Injury (BI)',
                fieldValues: {
                  per_person_limit: [perPersonLimitBI],
                  per_accident_limit: [perAccidentLimitBI]
                }
              },
              {
                name: 'Comprehensive',
                fieldValues: {
                  actual_cash_value_of_the_vehicle_and_optional_equipment: [
                    '60000'
                  ],
                  helmet_and_safety_apparel: ['2000'],
                  deductibles: ['1000']
                }
              },
              {
                name: 'Collision',
                fieldValues: {
                  actual_cash_value_of_the_vehicle_and_optional_equipment: [
                    '60000'
                  ],
                  helmet_and_safety_apparel: ['2000'],
                  deductibles: ['1000']
                }
              }
            ]
          }
        ]
      }
    } else if (vehicle === 'Snowmobile') {
      return {
        finalize: 'false',
        policyholderLocator: policyholderlocator,
        productName: 'Boundless Rider Motorcycle and Motorsports Program',
        policyStartTimestamp: new Date().getTime(),
        policyEndTimestamp: new Date(
          new Date().getFullYear() + 1,
          new Date().getMonth(),
          new Date().getDate()
        ).getTime(),
        fieldValues: {
          driving_history: history,
          driver_Age: age,
          gender: 'Male',
          marital_status: 'Married',
          'operator’s_date_of_birth': '1957-09-08',
          any_prior_insurance_with_any_carrier: 'No',
          insurance_score: 'No hit',
          sales_channel: 'Direct online',
          age_credit_home_owner: 'Own',
          house_hold_composition: '2',
          drug_alcohol_program: 'No',
          safety_course: 'Yes',
          safety_course_date: formatDate(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 3,
              new Date().getDate()
            )
          ),
          mvr_surcharge: 'No foreign or international license',
          license_state: state,
          driving_experience: '5',
          motorcycle_endorsement: 'Yes',
          safe_rider_program: 'No',
          motor_group: 'No',
          addl_operators: 'No',
          felony: 'No',
          insurance_fraud_conviction: 'No',
          insurance_fraud_conviction_insured: 'No',
          atfault_claims_past_3_years: 'No',
          multiline_discount: '0',
          quote_type: 'Quick Quote',
          payment_plan: 'Automatic',
          user_id: anonymous_user,
          ...agent,
          ...agency,
          utm_tracking_string
        },
        exposures: [
          {
            exposureName: 'Vehicle',
            fieldValues: {
              year: selyear,
              vehicle_type: vehicle,
              state: state,
              model: selmodel,
              postal_code: zip,
              make: selmake,
              symbol_misc_code: '0',
              vehicle_class: 'Snowmobile',
              series_name: vehicleDetails?.model ? vehicleDetails?.model : '',
              body_type: vehicleDetails?.body_type,
              msrp: vehicleDetails?.msrp,
              engine_size_cc_kw: vehicleDetails?.displacement,
              anti_theft: 'No',
              vehicle_modification: 'No',
              multiple_vehicle_discount: '1',
              territory_city: 'Pearland',
              territory_state: state,
              territory_postalcode: zip,
              primary_vehicle_use_hire: 'No',
              primary_vehicle_use_nonhire: 'No',
              road_use: 'No',
              garaging: 'Yes',
              ownership_status: 'Owned',
              snowmobile_adjustment: 'Yes'
            },
            perils: [
              {
                name: 'Guest Passenger',
                fieldValues: {
                  per_person_limit: [perPersonLimitGP],
                  per_accident_limit: [perAccidentLimitGP]
                }
              },
              {
                name: 'Property Damage',
                fieldValues: {
                  limit: [propertyDamage]
                }
              },
              {
                name: 'Bodily Injury (BI)',
                fieldValues: {
                  per_person_limit: [perPersonLimitBI],
                  per_accident_limit: [perAccidentLimitBI]
                }
              },
              {
                name: 'Comprehensive',
                fieldValues: {
                  actual_cash_value_of_the_vehicle_and_optional_equipment: [
                    '60000'
                  ],
                  helmet_and_safety_apparel: ['2000'],
                  deductibles: ['1000']
                }
              },
              {
                name: 'Collision',
                fieldValues: {
                  actual_cash_value_of_the_vehicle_and_optional_equipment: [
                    '60000'
                  ],
                  helmet_and_safety_apparel: ['2000'],
                  deductibles: ['1000']
                }
              }
            ]
          }
        ]
      }
    }
  }

  const quotePrice = async (quotelocator) => {
    return fetch('/api/usercontroller/quote/quoteprice', {
      method: 'POST',
      body: JSON.stringify({ locator: quotelocator }),
      headers: {
        // Authorization: params.auth?.authorizationToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Encoding':
          'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (parseFloat(data.status) >= 400 || data.message) {
          setRetryPopUp(true)
          setLoader(false)
          return false
        } else {
          setLoader(false)
          setRetryPopUp(false)
          dispatch(setQuoteData(data))
          router.push(ROUTE_PATHS.QUOTE)
        }
      })
      .catch((error) => {
        setRetryPopUp(true)
        return false
      })
  }

  async function postdata() {
    setLoader(true)
    setRetryPopUp(false)
    if ((vehicle == 'Motorcycle' || vehicle == 'E-BikeAssist') && age < 15) {
      setAgeError(true)
      setAgeError1(false)
    } else if (
      (vehicle == 'Snowmobile' ||
        vehicle == 'Dirtbike' ||
        vehicle == 'ATV,UTV') &&
      age < 10
    ) {
      setAgeError(false)
      setAgeError1(true)
    } else {
      setAgeError(false)
      setAgeError1(false)
      setAgeError2(false)
      dispatch(setZipCode(zip))

      if (vehicle == 'E-BikeAssist') {
        dispatch(setYearData(year))
        dispatch(setMakeData(make))
        dispatch(setModelData(model))
        localStorage.setItem('Year', year)
        localStorage.setItem('Make', make)
        localStorage.setItem('Model', model)
      } else {
        dispatch(setYearData(selyear))
        dispatch(setMakeData(selmake))
        dispatch(setModelData(selmodel))
        localStorage.setItem('Year', selyear)
        localStorage.setItem('Make', selmake)
        localStorage.setItem('Model', selmodel)
      }

      const body = {
        values: {
          last_name: 'Test',
          first_name: 'Test',
          middle_initial: 'Test',
          suffix: 'Jr.'
        }
      }
      return fetch('/api/usercontroller/quote/createpolicyholder', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          // Authorization: params.auth?.authorizationToken,

          'Content-Type': 'application/json',

          Accept: 'application/json',
          'Accept-Encoding':
            'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
        }
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (parseFloat(data.status) >= 400) {
            setLoader(false)
            setRetryPopUp(true)
            return false
          } else {
            setRetryPopUp(false)
            setLoader(true)
            const policyholderlocator = data.locator
            setPolicyHolder(policyholderlocator)
            const policy = payload(policyholderlocator)

            return fetch('/api/usercontroller/quote/createpolicy', {
              method: 'POST',
              body: JSON.stringify(policy),
              headers: {
                // Authorization: params.auth?.authorizationToken,
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Accept-Encoding':
                  'gzip' | 'compress' | 'deflate' | 'br' | 'identity' | '*'
              }
            })
              .then((response) => response.json())
              .then(async (data1) => {
                if (parseFloat(data1.status) >= 400) {
                  setRetryPopUp(true)
                  setLoader(false)
                  return false
                } else {
                  setRetryPopUp(false)
                  setLoader(true)
                  const policylocator = data1.locator
                  dispatch(setPolicyLocator(policylocator))
                  return fetch('/api/usercontroller/quote/fetchallquotes', {
                    method: 'POST',
                    body: JSON.stringify(policylocator),
                    headers: {
                      // Authorization: params.auth?.authorizationToken,
                      'Content-Type': 'application/json',

                      Accept: 'application/json',
                      'Accept-Encoding':
                        'gzip' |
                        'compress' |
                        'deflate' |
                        'br' |
                        'identity' |
                        '*'
                    }
                  })
                    .then((response) => response.json())
                    .then((data2) => {
                      if (parseFloat(data2.status) >= 400) {
                        setRetryPopUp(true)
                        setLoader(false)
                        return false
                      } else {
                        setRetryPopUp(false)
                        setLoader(true)
                        let quoteBody = {
                          driverAge: age,

                          vehicleType: vehicle,

                          vehicleMake:
                            vehicle == 'E-BikeAssist' ? make : selmake,

                          model: vehicle == 'E-BikeAssist' ? model : selmodel,

                          year: vehicle == 'E-BikeAssist' ? year : selyear,

                          mvr: history,

                          territoryPostalCode: zip,

                          quotelocator: data2[0].locator,

                          activeDate: moment().format('MM-DD-YYYY')
                        }
                        dispatch(setQuoteLocator(data2[0].locator))
                        dispatch(getQuote({ body: quoteBody }))
                        quotePrice(quoteBody?.quotelocator)
                        localStorage.setItem('Vehice_Type', vehicle)

                        // dispatch(
                        //   selectQuotes({ auth: auth, locator: data2[0].locator })
                        // )
                      }
                    })

                    .catch((error) => {})
                }
              })

              .catch((error) => {})
          }
        })

        .catch((error) => {})
    }
  }

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url,
          title,
          description
        }}
      />

      <>
        <div className="relative">
          <main className="lg:relative">
            <div
              className="
            left-padding sm:pt-7">
              <div className="qq-input-page p-2 sm:p-0">
                {retryPopUp && (
                  <RetryModal
                    closeRetryModal={() => {
                      setRetryPopUp(false)
                    }}
                    retryFunction={postdata}
                  />
                )}

                <div className="flex">
                  <div className="p-card">
                    <div className="mb-3 pl-1">
                      <p
                        className="text-left text-2xl sm:text-5xl font-Oswald font-semibold"
                        style={{ color: '#007DB8' }}>
                        Let's Get Started!
                      </p>
                    </div>
                    <div className="mb-3 pl-1 mt-4">
                      <p className="text-left text-base sm:font-bold font-Sans font-normal font-color-asphalt-gray">
                        ENTER YOUR ZIP
                      </p>
                    </div>
                    <div className="flex justify-content-start sm:align-items-start pl-1 mu-zip">
                      <div className="mu-zip">
                        <InputMask
                          id="zip"
                          name="zip"
                          mask="99999"
                          ref={inputMarkRef}
                          value={zip}
                          slotChar=""
                          disabled={disabelzip}
                          onChange={zipHandler}
                          className="p-inputtext-sm block outline-none font-Oswald oswald font-semibold text-2xl partitioned opacity-100 inputtext pr-2"
                        />
                      </div>
                    </div>

                    <div
                      className={`${
                        click ? 'hidden' : 'block flex justify-content-start'
                      }`}>
                      <Button
                        type="button"
                        onClick={handleClick}
                        disabled={!(zip != '')}
                        className="mt-5 h-10 font-semibold font-bold py-4 sm:py-2 px-4 w-auto text-white button-orange-bg">
                        START QUICK QUOTE
                      </Button>
                    </div>
                    <div className={`${click ? 'block mt-3' : 'hidden'}`}>
                      <div className="flex flex-row column-gap-2 my-4 text-left font-semibold text-2xl font-Oswald font-color-asphalt-gray">
                        <p>Select your vehicle type</p>
                        <div className="tooltip-padding-top tooltip-target">
                          <img src={IMAGE_PATHS.QUESTION_MARK_CIRCLE} />
                        </div>
                        <div>
                          <Tooltip
                            target=".tooltip-target"
                            position="right"
                            className="w-22rem">
                            <p>
                              Our Quick Quote can only estimate one vehicle at a
                              time. If you would like to get a price estimate on
                              all your vehicles at once, go to Personalized
                              Quote.
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="flex flex-row flex-wrap column-gap-3 row-gap-3 sm:column-gap-4  sm:flex-nowrap mb-4 sm:pl-0">
                        <div className="cursor-pointer">
                          {vehicle == '' || vehicle != 'Motorcycle' ? (
                            <img
                              className="h-5rem sm:h-6rem"
                              src={IMAGE_PATHS.MOTORCYCLE_GRAY_BG}
                              onClick={() => {
                                resetFields()
                                setVehicle('Motorcycle')
                                setVehicleError(false)
                                getYearData('Motorcycle')
                              }}
                            />
                          ) : (
                            <img
                              className="h-5rem sm:h-6rem"
                              src={IMAGE_PATHS.MOTORCYCLE_SELECTED}
                            />
                          )}
                          <div
                            className={`${
                              vehicle == 'Motorcycle' ? 'block' : 'hidden'
                            } pt-1`}
                            style={{ borderBottom: '5px solid #FFAF13' }}></div>
                        </div>
                        <div className="cursor-pointer">
                          {vehicle == '' || vehicle != 'ATV,UTV' ? (
                            <img
                              className="h-5rem sm:h-6rem"
                              src={IMAGE_PATHS.ATV_UTV_GRAY_BG}
                              onClick={() => {
                                resetFields()
                                setVehicle('ATV,UTV')
                                setVehicleError(false)
                                getYearData('ATV,UTV')
                              }}
                            />
                          ) : (
                            <img
                              className="h-5rem sm:h-6rem"
                              src={IMAGE_PATHS.ATV_UTV_SELECTED}
                            />
                          )}
                          <div
                            className={`${
                              vehicle == 'ATV,UTV' ? 'block' : 'hidden'
                            } pt-1`}
                            style={{ borderBottom: '5px solid #FFAF13' }}></div>
                        </div>
                        <div className="cursor-pointer">
                          {vehicle == '' || vehicle != 'Dirtbike' ? (
                            <img
                              className="h-5rem sm:h-6rem"
                              src={IMAGE_PATHS.DIRTBIKE_GRAY_BG}
                              onClick={() => {
                                resetFields()
                                setVehicle('Dirtbike')
                                setVehicleError(false)
                                getYearData('Dirtbike')
                              }}
                            />
                          ) : (
                            <img
                              className="h-5rem sm:h-6rem"
                              src={IMAGE_PATHS.DIRTBIKE_SELECTED}
                            />
                          )}
                          <div
                            className={`${
                              vehicle == 'Dirtbike' ? 'block' : 'hidden'
                            } pt-1`}
                            style={{ borderBottom: '5px solid #FFAF13' }}></div>
                        </div>
                        {/* <div className=" cursor-pointer">
                          {vehicle == '' || vehicle != 'E-BikeAssist' ? (
                            <img
                              className="h-5rem sm:h-6rem"
                              src={IMAGE_PATHS.EBIKE_GRAY_BG}
                              onClick={() => {
                                resetFields()
                                setVehicle('E-BikeAssist')
                                setVehicleError(false)
                              }}
                            />
                          ) : (
                            <img
                              className="h-5rem sm:h-6rem"
                              src={IMAGE_PATHS.EBIKE_SELECTED}
                            />
                          )}
                          <div
                            className={`${
                              vehicle == 'E-BikeAssist' ? 'block' : 'hidden'
                            } pt-1`}
                            style={{ borderBottom: '5px solid #FFAF13' }}></div>
                        </div> */}
                        <div className=" cursor-pointer">
                          {vehicle == '' || vehicle != 'Snowmobile' ? (
                            <img
                              className="h-5rem sm:h-6rem"
                              src={IMAGE_PATHS.SNOWMOBILE_GRAY_BG}
                              onClick={() => {
                                resetFields()
                                setVehicle('Snowmobile')
                                setVehicleError(false)
                                getYearData('Snowmobile')
                              }}
                            />
                          ) : (
                            <img
                              className="h-5rem sm:h-6rem"
                              src={IMAGE_PATHS.SNOWMOBILE_SELECTED}
                            />
                          )}
                          <div
                            className={`${
                              vehicle == 'Snowmobile' ? 'block' : 'hidden'
                            } pt-1`}
                            style={{ borderBottom: '5px solid #FFAF13' }}></div>
                        </div>
                      </div>
                      {vehicleerror && (
                        <small id="zip" className="p-error block">
                          Select any vehicle
                        </small>
                      )}
                      <p className="text-4xl font-normal font-Oswald text-left mb-3 font-color-asphalt-gray mt-5">
                        Just a few quick questions
                      </p>
                      <p className="hidden sm:block font-normal font-Oswald text-2xl mb-3 text-left font-color-asphalt-gray">
                        Vehicle Basics
                      </p>
                      {vehicle == 'E-BikeAssist' ? (
                        <div className="flex flex-column">
                          <div className="text-left mb-3 mt-2">
                            <p className="sm: ml-0 text-sm font-Sans font-bold mb-1 text-left font-color-asphalt-gray">
                              YEAR
                            </p>

                            <div className="age">
                              <InputMask
                                id="year"
                                name="year"
                                mask="9999"
                                value={year}
                                placeholder="Year"
                                onChange={(e) => setYear(e.target.value)}
                                className="p-inputtext-sm w-18rem sm:w-15rem block outline-none inputtext"
                              />{' '}
                            </div>
                          </div>
                          <div className="text-left mb-3 mt-3">
                            {' '}
                            <p className="sm: ml-0 text-sm font-Sans font-bold mb-1 font-color-asphalt-gray">
                              MAKE
                            </p>
                            <div className="age">
                              <InputText
                                id="make"
                                name="make"
                                value={make}
                                placeholder="Make"
                                onChange={(e) => setMake(e.target.value)}
                                aria-describedby=""
                                className="p-inputtext-sm text-sm w-18rem sm:w-15rem block outline-none inputtext"
                              />{' '}
                            </div>
                          </div>
                          <div className="text-left mb-3 mt-3">
                            <p className="sm: ml-0 text-sm font-Sans font-bold mb-1 font-color-asphalt-gray">
                              MODEL
                            </p>
                            <div className="age">
                              <InputText
                                id="model"
                                name="model"
                                value={model}
                                placeholder="Model"
                                onChange={(e) => setModel(e.target.value)}
                                aria-describedby=""
                                className="p-inputtext-sm text-sm w-18rem sm:w-15rem block outline-none inputtext"
                              />{' '}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-column dropdown">
                          <div className="text-left mb-3 mt-2">
                            <p className="sm:ml-0 text-sm font-Sans font-bold mb-1 text-left font-color-asphalt-gray">
                              YEAR
                            </p>

                            <div>
                              <FormControl
                                variant="filled"
                                sx={{ m: 0, minWidth: 120 }}>
                                <Select
                                  labelId="year"
                                  id="year"
                                  className="p-inputtext-sm text-sm outline-none w-18rem sm:w-15rem"
                                  value={selyear}
                                  disabled={yearFlag}
                                  onChange={(e) =>
                                    getVehicleYear(e.target.value)
                                  }>
                                  {year != '' &&
                                    year?.map((item, index) => {
                                      return (
                                        <MenuItem key={index} value={item}>
                                          {item}
                                        </MenuItem>
                                      )
                                    })}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <div className="text-left mb-3 mt-3 dropdown">
                            {' '}
                            <p className="sm: ml-0 text-sm font-Sans font-bold mb-1 font-color-asphalt-gray">
                              MAKE
                            </p>
                            <div>
                              <FormControl
                                variant="filled"
                                sx={{ m: 0, minWidth: 120 }}>
                                <Select
                                  labelId="make"
                                  id="make"
                                  value={selmake}
                                  disabled={makeFlag}
                                  className="p-inputtext-sm text-sm outline-none w-18rem sm:w-15rem"
                                  onChange={(e) =>
                                    getVehicleMake(e.target.value)
                                  }>
                                  {make != '' &&
                                    make?.map((item, index) => {
                                      return (
                                        <MenuItem key={index} value={item}>
                                          {item}
                                        </MenuItem>
                                      )
                                    })}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <div className="text-left mb-3 mt-3 dropdown">
                            <p className="sm: ml-0 text-sm font-Sans font-bold mb-1 font-color-asphalt-gray">
                              MODEL
                            </p>
                            <div>
                              <FormControl
                                variant="filled"
                                sx={{ m: 0, minWidth: 120 }}>
                                <Select
                                  labelId="model"
                                  id="model"
                                  className="p-inputtext-sm text-sm outline-none w-18rem sm:w-15rem"
                                  value={selmodel}
                                  disabled={modelFlag}
                                  onChange={(e) =>
                                    getVehicleModel(e.target.value)
                                  }>
                                  {model != '' &&
                                    model?.map((item, index) => {
                                      return (
                                        <MenuItem key={index} value={item}>
                                          {item}
                                        </MenuItem>
                                      )
                                    })}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          {open && (
                            <Modal
                              showsModal={open}
                              closeModal={(closeModalError, backToHome)}
                              description={
                                <>
                                  <div className="px-3 text-center">
                                    <p className="font-semibold text-black text-lg mb-3">
                                      We have been unable to gather all the
                                      information about your vehicle from our
                                      3rd party data provider.
                                    </p>
                                    <div className="text-base text-black mb-4 font-normal">
                                      <p>
                                        Please review the information you
                                        entered.
                                      </p>
                                    </div>
                                    <div className="text-base text-black mb-4 font-normal">
                                      <p>
                                        If you are still unable to proceed
                                        please call us on (800)-814-7231
                                      </p>
                                    </div>
                                  </div>
                                </>
                              }
                              body={
                                <>
                                  <div className="flex flex-row column-gap-3 justify-content-center">
                                    <Button
                                      type="button"
                                      onClick={closeModalError}
                                      className="my-2 h-10 w-7rem font-bold py-3 sm:py-2 px-4 text-black border-2 border-orange-600 bg-white">
                                      <span className="text-black">CLOSE</span>
                                    </Button>
                                    <Button
                                      type="button"
                                      onClick={closeModalError}
                                      className="my-2 h-10 w-auto font-bold py-2 px-4 text-white  button-orange-bg">
                                      RETURN TO HOME
                                    </Button>
                                  </div>
                                </>
                              }
                            />
                          )}
                        </div>
                      )}

                      <div>
                        <p className="font-normal text-4xl mb-3 sm:mt-2 font-Oswald text-left font-color-asphalt-gray">
                          Tell us a little about yourself
                        </p>
                        <div className="mb-3">
                          <div className="flex flex-row column-gap-2">
                            <p className="text-sm font-bold font-Sans mb-1">
                              AGE
                            </p>
                            <div className="pt-1 tooltip-target3">
                              <img src={IMAGE_PATHS.QUESTION_MARK_CIRCLE} />
                            </div>
                            <div>
                              <Tooltip
                                target=".tooltip-target3"
                                position="right"
                                className="w-22rem">
                                <p>
                                  To obtain a Boundless Rider insurance, the
                                  policyholder must be at least 18 years old.
                                </p>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="age">
                            <div className="flex flex-row column-gap-3">
                              <InputMask
                                id="Age"
                                name="Age"
                                mask="99"
                                value={age}
                                placeholder="Age"
                                onChange={agevalidation}
                                className="p-input 25 block mb-2 outline-none w-5rem inputtext text-base sm:text-sm"
                              />
                              <div>
                                <p className="text-sm font-bold pt-2 font-color-asphalt-gray">
                                  years old
                                </p>
                              </div>
                            </div>
                            {policyAgeError && (
                              <small id="zip" className="p-error block">
                                Minimum age must be 18.
                              </small>
                            )}
                            {ageerror && (
                              <small id="zip" className="p-error block">
                                {state === 'Illinois'
                                  ? 'Minimum age must be 18.'
                                  : 'Minimum age must be 15.'}
                              </small>
                            )}
                            {ageerror1 && (
                              <small id="zip" className="p-error block">
                                Minimum age must be 10.
                              </small>
                            )}
                            {ageerror2 && (
                              <small id="zip" className="p-error block">
                                This field is mandatory.
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 ">
                          <div className="flex flex-row column-gap-2">
                            <p className="text-sm  font-bold font-Sans mb-1 text-left font-color-asphalt-gray">
                              DRIVING HISTORY (PAST 3 YEARS)
                            </p>
                            <div className="pt-1 tooltip-target2">
                              <img src={IMAGE_PATHS.QUESTION_MARK_CIRCLE} />
                            </div>
                            <div>
                              <Tooltip
                                target=".tooltip-target2"
                                position="right"
                                className="w-22rem">
                                <p>
                                  This should include your entire motor vehicle
                                  record, including auto violations and
                                  accidents.
                                </p>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="flex flex-row column-gap-3 mt-2">
                            <div className="">
                              {history == false || history != 'Perfect' ? (
                                <img
                                  src={IMAGE_PATHS.PERFECT_WHITE}
                                  className="h-4rem cursor-pointer"
                                  onClick={() => setHistory('Perfect')}
                                />
                              ) : (
                                <img
                                  src={IMAGE_PATHS.PERFECT_ORANGE}
                                  className="h-4rem"
                                />
                              )}
                              <div
                                className={`${
                                  history == 'Perfect' ? 'block' : 'hidden'
                                } pt-1`}
                                style={{
                                  borderBottom: '5px solid #FFAF13'
                                }}></div>
                            </div>
                            <div className="">
                              {history == false || history != 'Good' ? (
                                <img
                                  src={IMAGE_PATHS.OKAY_WHITE}
                                  className="h-4rem cursor-pointer"
                                  onClick={() => setHistory('Good')}
                                />
                              ) : (
                                <img
                                  src={IMAGE_PATHS.OKAY_ORANGE}
                                  className="h-4rem"
                                />
                              )}
                              <div
                                className={`${
                                  history == 'Good' ? 'block' : 'hidden'
                                } pt-1`}
                                style={{
                                  borderBottom: '5px solid #FFAF13'
                                }}></div>
                            </div>
                            <div className="">
                              {history == false || history != 'Okay' ? (
                                <img
                                  src={IMAGE_PATHS.NOT_GOOD_WHITE}
                                  className="h-4rem cursor-pointer"
                                  onClick={() => setHistory('Okay')}
                                />
                              ) : (
                                <img
                                  src={IMAGE_PATHS.NOT_GOOD_ORANGE}
                                  className="h-4rem"
                                />
                              )}
                              <div
                                className={`${
                                  history == 'Okay' ? 'block' : 'hidden'
                                } pt-1`}
                                style={{
                                  borderBottom: '5px solid #FFAF13'
                                }}></div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3">
                          <p className="text-sm text-gray-600">
                            Perfect: No Moving Violations/Accidents
                            <br />
                            Okay: 2 Minor Violations <br />
                            Not Good: 1 Minor and 1 Major Violation & 1 At Fault
                            Accident
                          </p>
                        </div>
                      </div>
                      <div className="block flex justify-content-center sm:justify-content-start">
                        <Modal
                          showsModal={loader}
                          description={
                            <div className="flex flex-column justify-content-center text-center mb-3">
                              <div>
                                <p className="text-4xl headline-blue font-Oswald mb-2 font-bold">
                                  Please wait a few seconds…
                                </p>
                              </div>
                              <div>
                                <p className="font-color-asphalt-gray-80 font-bold text-base">
                                  We are just getting your premiums
                                </p>
                              </div>
                            </div>
                          }
                          body={
                            <>
                              <div className="flex flex-row column-gap-3 justify-content-center mb-4">
                                <ThreeDots
                                  radius="9"
                                  color="#A52A2A"
                                  visible={true}
                                />
                              </div>{' '}
                            </>
                          }
                        />

                        <Button
                          type="button"
                          disabled={
                            ageerror ||
                            ageerror1 ||
                            ageerror2 ||
                            vehicleerror ||
                            age?.length != 2 ||
                            zip?.length != 5 ||
                            vehicle == '' ||
                            history == '' ||
                            year == '' ||
                            make == '' ||
                            model == '' ||
                            vehicleDataIsValid == false ||
                            loader
                          }
                          onClick={postdata}
                          className="button-orange-bg mt-4 mb-8 h-10 font-bold py-4 sm:py-2 px-4 sm:w-11rem w-15rem text-white">
                          {loader ? (
                            <ThreeDots
                              radius="9"
                              color="#Ffffff"
                              visible={true}
                            />
                          ) : (
                            <div className="font-semibold text-sm text-white ">
                              GET QUICK QUOTE
                            </div>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    </>
  )
}

export default connect(null, null)(Home)

export async function getServerSideProps(context) {
  return {
    props: {} // will be passed to the page component as props
  }
}
